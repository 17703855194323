var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-row',[_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('page-content',{attrs:{"breadcrumbTitle":true,"breadcrumb":[
        {
          title: 'Components',
          link: '/components/components-page',
        },
        {
          title: 'Data Display',
        },
      ]},scopedSlots:_vm._u([{key:"desc",fn:function(){return [_c('p',{staticClass:"h5 mb-0 mt-24 hp-text-color-black-0"},[_vm._v(" List Groups are a flexible and powerful component for displaying a series of content. List Group items can be modified to support just about any content within. They can also be used as navigation via various props. ")])]},proxy:true}])})],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('basic')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('active')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('disabled')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('actionable')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('variant')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('badges')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('cards')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('horizontal')],1),_c('b-col',{staticClass:"mb-32",attrs:{"cols":"12"}},[_c('custom')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }